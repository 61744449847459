<template>
  <v-content>
    <div class="page">
      <div class="imgweb d-none d-sm-none d-md-block mt-0 pt-0">
        <AppBar :drawer="false"/>
        <v-container>
          <v-row class="mt-5">
            <v-col cols="12">
              <v-card
                style="color: #70445E;background-color: transparent;"
              >
                <v-card-title class="subtitle-2">COLUNA EN DEHORS</v-card-title>
                <v-card-text
                  class="black--text"
                  style="margin-bottom: 2vh;"
                  v-html="DadosTexto.Texto"
                ></v-card-text>
              </v-card>
            </v-col>
            <v-col cols="12" class="py-0 my-0 text-body-2" style="color:#70445E;"><span>LEIA AGORA</span></v-col>
            <v-col cols="4">
              <v-btn
                block
                color="#70445E"
                class="white--text"
                @click="$router.push('edicaomai-jun');"
              >EDIÇÃO DE MAI/JUN 2022</v-btn>
            </v-col>
            <v-col cols="4">
              <v-btn
                block
                color="#70445E"
                class="white--text"
                @click="$router.push('edicaojul-ago');"
              >EDIÇÃO DE JUL/AGO 2022</v-btn>
            </v-col>
            <v-col cols="4">
              <v-btn
                block
                color="#70445E"
                class="white--text"
                @click="$router.push('edicaoset-out');"
              >EDIÇÃO DE SET/OUT 2022</v-btn>
            </v-col>
          </v-row>
        </v-container>
      </div>
      <div class="imgmobi d-md-none d-lg-none d-xl-none pt-0 mt-0" style="overflow-y: auto !important;" v-scroll.self="onScroll">
        <AppBar :drawer="false"/>
        <div class="container" id="topocoluna">
          <v-card
            style="color: #70445E;background-color: transparent;"
          >
            <v-card-title class="subtitle-2">COLUNA EN DEHORS</v-card-title>
            <v-card-text
              class="black--text"
              style="margin-bottom: 2vh;"
              v-html="DadosTexto.Texto"
            ></v-card-text>
          </v-card>
          <v-btn
            block
            color="#70445E"
            class="white--text my-1"
            @click="$router.push('edicaomai-jun');"
          >EDIÇÃO DE MAI/JUN 2022</v-btn>
          <v-btn
            block
            color="#70445E"
            class="white--text my-1"
            @click="$router.push('edicaojul-ago');"
          >EDIÇÃO DE JUL/AGO 2022</v-btn>
          <v-btn
            block
            color="#70445E"
            class="white--text my-1"
            @click="$router.push('edicaoset-out');"
          >EDIÇÃO DE SET/OUT 2022</v-btn>
        </div>
      </div>
    </div>
  </v-content>
</template>

<script>
import AppBar from '../components/AppBar.vue'
export default {
  name: 'ColunaEnDehors',
  components: {
    AppBar
  },
  data () {
    return {
      DadosTexto: {
        Titulo: "COLUNA EN DEHORS",
        Texto: `Na qualidade de bailarina e escritora, a autora faz um laço entre essas formas de poesia – a das palavras e a dos movimentos – no trabalho como colunista da revista The Bard.<br /><br />
                A coluna En Dehors (para fora) importa o termo corriqueiro do ballet clássico, fazendo trocadilho para um gostoso convite à vazão das múltiplas formas de expressão.<br /><br />
                Aqui, nos textos da coluna, é possível ler o perfil de uma artista que cresceu talhada pela dança das palavras e pela poesia do movimento: um verdadeiro convite a bailar nesse universo de sublimação!
                <br /><br />
        `
      }
    }
  },
  methods: {
    gotoExt () {
      window.open(
        this.url,
        '_blank'
      );
    },
    gotoInt () {
      window.location.href = this.dirr
    },
    gotoPage (link) {
      var target = link === this.url ? '_blank' : '_self'
      window.open(
        link,
        target
      );
    },
    onScroll () {
      this.drawer ? this.drawer = false : this.drawer = true
    },
  },
}
</script>
<style scoped>
    @font-face {
      font-family: Letra;
      src: url('../assets/fonts/OpenSans-VariableFont_wdth,wght.ttf');
    }
    @font-face {
      font-family: Laube;
      src: url('../assets/fonts/Blue Vinyl Fonts - BlueVinyl-Bold.otf');
    }
    .laubeFont {
      font-family: Letra !important;
      font-weight: bolder !important;
    }
    .imgweb {
      background-image: url('../assets/img/fundo2web.png');
      background-color: #fdede6;
      background-size: cover;
      background-position-y: -100px;
      background-attachment: fixed;
      min-height: 100vh;
      height: 100%;
      width: 100%;
      background-repeat: no-repeat;
    }
    .imgmobi {
      background-image: url('../assets/img/fundocel2.png');
      background-color: #d4c1ba;
      background-position: top;
      background-size: cover;
      height: 100vh;
      background-repeat: no-repeat;
    }
</style>
